<template>
    <div>
        <div class="mt-9 overflow-y-scroll no-scrollbar">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="pl-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Enroll Number
                    </th>
                    <th scope="col" class="pl-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                    </th>
                    <th scope="col" class="pl-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Course
                    </th>
                    <th scope="col" class="pl-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Father Name
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">edit</span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">view</span>
                    </th>
                </tr>
                </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="student in students" :key="student.id">

                  <td class="px-1 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.enrollNumber }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-1 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.name }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-1 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.course}}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-1 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ student.father_name}}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <router-link :to="'/edit/'+ student.id" class="text-indigo-600 hover:text-indigo-900" @click="deletecourse(student.id)">
                     Edit
                  </router-link>
                </td>
                <td class="px-1 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <router-link :to="'/student/'+ student.id" class="text-indigo-600 hover:text-indigo-900" @click="deletecourse(student.id)">
                     View
                  </router-link>
                </td>
              </tr>
            </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
    export default {
        name:'AllStudents',
        computed:{
            ...mapState(['students','total'])
        },
    }
</script>

<style>

</style>